<template>
<div>
   <b-row>
     <!-- value_added_tea_sale_qty -->
    <b-col xl="6" lg="6" sm="12">
        <ValidationProvider name="Value Added Tea Sale Qty" vid="value_added_tea_sale_qty" :rules="`required|max_value:${getStandardValue(1)}`">
        <b-form-group
            slot-scope="{ valid, errors }"
            label-for="value_added_tea_sale_qty">
            <template v-slot:label>
            {{ $t('portal.value_added_tea_sale_qty') }} <span class="text-danger">*</span>
            </template>
            <b-form-input
            id="value_added_tea_sale_qty"
            v-model="detail.value_added_tea_sale_qty"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
            :state="errors[0] ? false : (valid ? true : null)"
            ></b-form-input>
            <small class="validation_msg"><i :title="$t('portal.standard_value') + ' ' + $n(getStandardValue(1))" class="ri-folder-info-line"></i><b> {{$t('portal.standard_value')}} {{$n(getStandardValue(1))}}</b></small>
            <div class="invalid-feedback">
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
     <!-- max_gross_annual_production_local -->
    <b-col xl="6" lg="6" sm="12">
        <ValidationProvider name="Maximum gross annual production (Local market)" vid="max_gross_annual_production_local" :rules="`required|max_value:${getStandardValue(2)}`">
        <b-form-group
            slot-scope="{ valid, errors }"
            label-for="max_gross_annual_production_local">
            <template v-slot:label>
            {{ $t('portal.max_gross_annual_production_local') }} <span class="text-danger">*</span>
            </template>
            <b-form-input
            id="max_gross_annual_production_local"
            v-model="detail.max_gross_annual_production_local"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
            :state="errors[0] ? false : (valid ? true : null)"
            ></b-form-input>
            <small class="validation_msg"><i :title="$t('portal.standard_value') + ' ' + $n(getStandardValue(2))" class="ri-folder-info-line"></i><b> {{$t('portal.standard_value')}} {{$n(getStandardValue(2))}}</b></small>
            <div class="invalid-feedback">
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
     <!-- max_gross_annual_production_foreign -->
    <b-col xl="6" lg="6" sm="12">
        <ValidationProvider name="Maximum gross annual production (Forein market)" vid="max_gross_annual_production_foreign" :rules="`required|max_value:${getStandardValue(3)}`">
        <b-form-group
            slot-scope="{ valid, errors }"
            label-for="max_gross_annual_production_foreign">
            <template v-slot:label>
            {{ $t('portal.max_gross_annual_production_foreign') }} <span class="text-danger">*</span>
            </template>
            <b-form-input
            id="max_gross_annual_production_foreign"
            v-model="detail.max_gross_annual_production_foreign"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
            :state="errors[0] ? false : (valid ? true : null)"
            ></b-form-input>
            <small class="validation_msg"><i :title="$t('portal.standard_value') + ' ' + $n(getStandardValue(3))" class="ri-folder-info-line"></i><b> {{$t('portal.standard_value')}} {{$n(getStandardValue(3))}}</b></small>
            <div class="invalid-feedback">
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
   </b-row>
</div>
</template>
<script>
  import { teaGardenServiceBaseUrl } from '@/config/api_config'
  export default {
      name: 'Category6',
       props: ['detail', 'categoryDetails'],
      data () {
          return {
              teaGardenServiceBaseUrl: teaGardenServiceBaseUrl
          }
      },
      computed: {
        yesNoList () {
            return [
                {
                    value: 1,
                    text: this.$i18n.locale === 'en' ? 'Yes' : 'হ্যাঁ'
                },
                {
                    value: 0,
                    text: this.$i18n.locale === 'en' ? 'No' : 'না'
                }
            ]
        }
      },
      methods: {
        getStandardValue (key) {
           return this.categoryDetails.find((value, index) => parseInt(index + 1) === key).standard_value
        }
      }
  }
</script>
<style>
</style>
