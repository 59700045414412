<template>
<div>
   <b-row>
    <!-- details data start from here -->
        <b-col xl="6" lg="6" sm="12">
            <ValidationProvider name="Average production per acre" vid="avg_prod_per_acor" :rules="`required|max_value:${getStandardValue(1)}`">
            <b-form-group
                slot-scope="{ valid, errors }"
                label-for="avg_prod_per_acor">
                <template v-slot:label>
                {{ $t('portal.avg_prod_per_acor') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                id="avg_prod_per_acor"
                v-model="detail.avg_prod_per_acor"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <small class="validation_msg"><i :title="$t('portal.standard_value') + ' ' + $n(getStandardValue(1))" class="ri-folder-info-line"></i><b> {{$t('portal.standard_value')}} {{$n(getStandardValue(1))}}</b></small>
                <div class="invalid-feedback">
                {{ errors[0] }}
                </div>
            </b-form-group>
            </ValidationProvider>
        </b-col>
        <b-col xl="6" lg="6" sm="12">
            <ValidationProvider name="Average price of tea at auction " vid="avg_price_of_tea_auction" :rules="`required|max_value:${getStandardValue(2)}`">
            <b-form-group
                slot-scope="{ valid, errors }"
                label-for="avg_price_of_tea_auction">
                <template v-slot:label>
                {{ $t('portal.avg_price_of_tea_auction') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                id="avg_price_of_tea_auction"
                v-model="detail.avg_price_of_tea_auction"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <small class="validation_msg"><i :title="$t('portal.standard_value') + ' ' + $n(getStandardValue(2))" class="ri-folder-info-line"></i><b> {{$t('portal.standard_value')}} {{$n(getStandardValue(2))}}</b></small>
                <div class="invalid-feedback">
                {{ errors[0] }}
                </div>
            </b-form-group>
            </ValidationProvider>
        </b-col>
        <b-col xl="6" lg="6" sm="12">
            <ValidationProvider name="Gross annual production" vid="gross_annual_production" :rules="`required|max_value:${getStandardValue(3)}`">
            <b-form-group
                slot-scope="{ valid, errors }"
                label-for="gross_annual_production">
                <template v-slot:label>
                {{ $t('portal.gross_annual_production') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                id="gross_annual_production"
                v-model="detail.gross_annual_production"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <small class="validation_msg"><i :title="$t('portal.standard_value') + ' ' + $n(getStandardValue(3))" class="ri-folder-info-line"></i><b> {{$t('portal.standard_value')}} {{$n(getStandardValue(3))}}</b></small>
                <div class="invalid-feedback">
                {{ errors[0] }}
                </div>
            </b-form-group>
            </ValidationProvider>
        </b-col>
        <!-- tea_cultivation_land_percentage -->
        <b-col xl="6" lg="6" sm="12">
            <ValidationProvider name="Tea plantations under tea cultivable land (%)" vid="tea_cultivation_land_percentage" :rules="`required|max_value:${getStandardValue(4)}`">
            <b-form-group
                slot-scope="{ valid, errors }"
                label-for="tea_cultivation_land_percentage">
                <template v-slot:label>
                {{ $t('portal.tea_cultivation_land_percentage') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                id="tea_cultivation_land_percentage"
                v-model="detail.tea_cultivation_land_percentage"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <small class="validation_msg"><i :title="$t('portal.standard_value') + ' ' + $n(getStandardValue(4))" class="ri-folder-info-line"></i><b> {{$t('portal.standard_value')}} {{$n(getStandardValue(4))}}</b></small>
                <div class="invalid-feedback">
                {{ errors[0] }}
                </div>
            </b-form-group>
            </ValidationProvider>
        </b-col>
   </b-row>
</div>
</template>
<script>
  import { teaGardenServiceBaseUrl } from '@/config/api_config'
  export default {
      name: 'Category1',
       props: ['detail', 'categoryDetails'],
      data () {
          return {
              teaGardenServiceBaseUrl: teaGardenServiceBaseUrl
          }
      },
      methods: {
        getStandardValue (key) {
           return this.categoryDetails.find((value, index) => parseInt(index + 1) === key).standard_value
        }
      }
  }
</script>
<style>
.font-bn .btb_license_file_label .custom-file-label {
  font-size: 14px;
  color: #7c7c7c;
}
.validation_msg {
  font-size: 11px;
}
</style>
