<template>
<div>
   <b-row>
     <!-- number_of_workers -->
    <b-col xl="6" lg="6" sm="12">
        <ValidationProvider name="Number Of Registered Worker’s" vid="number_of_workers" rules="required">
        <b-form-group
            slot-scope="{ valid, errors }"
            label-for="number_of_workers">
            <template v-slot:label>
            {{ $t('portal.number_of_workers') }} <span class="text-danger">*</span>
            </template>
            <b-form-input
            id="number_of_workers"
            v-model="detail.number_of_workers"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
            :state="errors[0] ? false : (valid ? true : null)"
            ></b-form-input>
            <div class="invalid-feedback">
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
     <!-- number_of_house -->
    <b-col xl="6" lg="6" sm="12">
        <ValidationProvider name="Number of Residences" vid="number_of_house" :rules="`required|max_value:${detail.number_of_workers}`">
        <b-form-group
            slot-scope="{ valid, errors }"
            label-for="number_of_house">
            <template v-slot:label>
            {{ $t('portal.number_of_house') }} <span class="text-danger">*</span>
            </template>
            <b-form-input
            id="number_of_house"
            v-model="detail.number_of_house"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
            :state="errors[0] ? false : (valid ? true : null)"
            ></b-form-input>
             <small class="validation_msg"><i :title="$t('portal.standard_value') + ' ' + $n(detail.number_of_workers)" class="ri-folder-info-line"></i><b> {{$t('portal.standard_value')}} {{$n(detail.number_of_workers)}}</b></small>
            <div class="invalid-feedback">
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
    <!-- number_of_toilets -->
    <b-col xl="6" lg="6" sm="12">
        <ValidationProvider name="Number Of Toilets" vid="number_of_toilets" :rules="`required|max_value:${detail.number_of_workers}`">
        <b-form-group
            slot-scope="{ valid, errors }"
            label-for="number_of_toilets">
            <template v-slot:label>
            {{ $t('portal.number_of_toilets') }} <span class="text-danger">*</span>
            </template>
            <b-form-input
            id="number_of_toilets"
            v-model="detail.number_of_toilets"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
            :state="errors[0] ? false : (valid ? true : null)"
            ></b-form-input>
             <small class="validation_msg"><i :title="$t('portal.standard_value') + ' ' + $n(detail.number_of_workers)" class="ri-folder-info-line"></i><b> {{$t('portal.standard_value')}} {{$n(detail.number_of_workers)}}</b></small>
            <div class="invalid-feedback">
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
    <!-- number_of_tubewell -->
    <b-col xl="6" lg="6" sm="12">
        <ValidationProvider name="Number Of Tube Well" vid="number_of_tubewell" :rules="`required|max_value:${detail.number_of_workers}`">
        <b-form-group
            slot-scope="{ valid, errors }"
            label-for="number_of_tubewell">
            <template v-slot:label>
            {{ $t('portal.number_of_tubewell') }} <span class="text-danger">*</span>
            </template>
            <b-form-input
            id="number_of_tubewell"
            v-model="detail.number_of_tubewell"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
            :state="errors[0] ? false : (valid ? true : null)"
            ></b-form-input>
             <small class="validation_msg"><i :title="$t('portal.standard_value') + ' ' + $n(detail.number_of_workers)" class="ri-folder-info-line"></i><b> {{$t('portal.standard_value')}} {{$n(detail.number_of_workers)}}</b></small>
            <div class="invalid-feedback">
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
    <!-- have_hospital -->
    <b-col xl="6" lg="6" sm="12">
        <ValidationProvider name="Have Hospital" vid="have_hospital" rules="required|min_value:0" v-slot="{ errors }">
        <b-form-group
            label-for="have_hospital"
            label-cols-sm="4"
        >
            <template v-slot:label>
            {{ $t('portal.have_hospital') }} <span class="text-danger">*</span>
            </template>
            <b-form-radio-group
                style="margin-top:5px;"
                :id="have_hospital"
                v-model="detail.have_hospital"
                :options="yesNoList"
            ></b-form-radio-group>
            <div class="invalid-feedback d-block" >
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
    <!-- have_hospital_bed -->
    <b-col xl="6" lg="6" sm="12">
        <ValidationProvider name="Have Hospital Bed" vid="have_hospital_bed" rules="required" v-slot="{ errors }">
        <b-form-group
            label-for="have_hospital_bed"
            label-cols-sm="4"
        >
            <template v-slot:label>
            {{ $t('portal.have_hospital_bed') }} <span class="text-danger">*</span>
            </template>
            <b-form-radio-group
                style="margin-top:5px;"
                :id="have_hospital_bed"
                v-model="detail.have_hospital_bed"
                :options="yesNoList"
            ></b-form-radio-group>
            <div class="invalid-feedback d-block">
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
    <!-- have_doctors -->
    <b-col xl="6" lg="6" sm="12">
        <ValidationProvider name="Have Doctor's" vid="have_doctors" rules="required" v-slot="{ errors }">
        <b-form-group
            label-for="have_doctors"
            label-cols-sm="4"
        >
            <template v-slot:label>
            {{ $t('portal.have_doctors') }} <span class="text-danger">*</span>
            </template>
            <b-form-radio-group
                style="margin-top:5px;"
                :id="have_doctors"
                v-model="detail.have_doctors"
                :options="yesNoList"
            ></b-form-radio-group>
            <div class="invalid-feedback d-block">
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
    <!-- have_compounder -->
    <b-col xl="6" lg="6" sm="12">
        <ValidationProvider name="Have MLF/Compounder" vid="have_compounder" rules="required" v-slot="{ errors }">
        <b-form-group
            label-for="have_compounder"
            label-cols-sm="4"
        >
            <template v-slot:label>
            {{ $t('portal.have_compounder') }} <span class="text-danger">*</span>
            </template>
            <b-form-radio-group
                style="margin-top:5px;"
                :id="have_compounder"
                v-model="detail.have_compounder"
                :options="yesNoList"
            ></b-form-radio-group>
            <div class="invalid-feedback d-block">
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
    <!-- have_dispensary -->
    <b-col xl="6" lg="6" sm="12">
        <ValidationProvider name="Have Dispensory" vid="have_dispensary" rules="required" v-slot="{ errors }">
        <b-form-group
            label-for="have_dispensary"
            label-cols-sm="4"
        >
            <template v-slot:label>
            {{ $t('portal.have_dispensary') }} <span class="text-danger">*</span>
            </template>
            <b-form-radio-group
                style="margin-top:5px;"
                :id="have_dispensary"
                v-model="detail.have_dispensary"
                :options="yesNoList"
            ></b-form-radio-group>
            <div class="invalid-feedback d-block">
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
    <!-- number_of_students -->
    <b-col xl="4" lg="4" sm="12">
        <ValidationProvider name="Number Of Students Per Teacher" vid="number_of_students" rules="required" v-slot="{ errors }">
        <b-form-group
            label-for="number_of_students"
             label-cols-sm="6"
        >
            <template v-slot:label>
            {{ $t('portal.number_of_students') }} <span class="text-danger">*</span>
            </template>
            <b-form-input
            id="number_of_students"
            v-model="detail.number_of_students"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
            :state="errors[0] ? false : (valid ? true : null)"
            ></b-form-input>
            <div class="invalid-feedback">
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
    <b-col xl="2" lg="2" sm="12">
    </b-col>
    <!-- have_scholarship -->
    <b-col xl="6" lg="6" sm="12">
        <ValidationProvider name="Have Scholarship" vid="have_scholarship" rules="required" v-slot="{ errors }">
        <b-form-group
            label-for="have_scholarship"
            label-cols-sm="4"
        >
            <template v-slot:label>
            {{ $t('portal.have_scholarship') }} <span class="text-danger">*</span>
            </template>
            <b-form-radio-group
                style="margin-top:5px;"
                :id="have_scholarship"
                v-model="detail.have_scholarship"
                :options="yesNoList"
            ></b-form-radio-group>
            <div class="invalid-feedback d-block">
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
    <!-- have_playground -->
    <b-col xl="6" lg="6" sm="12">
        <ValidationProvider name="Have Playground" vid="have_playground" rules="required" v-slot="{ errors }">
        <b-form-group
            label-for="have_playground"
            label-cols-sm="4"
        >
            <template v-slot:label>
            {{ $t('portal.have_playground') }} <span class="text-danger">*</span>
            </template>
            <b-form-radio-group
                style="margin-top:5px;"
                :id="have_playground"
                v-model="detail.have_playground"
                :options="yesNoList"
            ></b-form-radio-group>
            <div class="invalid-feedback d-block">
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
    <!-- have_disable_people -->
    <b-col xl="6" lg="6" sm="12">
        <ValidationProvider name="Have Disable People" vid="have_disable_people" rules="required" v-slot="{ errors }">
        <b-form-group
            label-for="have_disable_people"
            label-cols-sm="4"
        >
            <template v-slot:label>
            {{ $t('portal.have_disable_people') }} <span class="text-danger">*</span>
            </template>
            <b-form-radio-group
                style="margin-top:5px;"
                :id="have_disable_people"
                v-model="detail.have_disable_people"
                :options="yesNoList"
            ></b-form-radio-group>
            <div class="invalid-feedback d-block">
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
    <!-- have_training_facility -->
    <b-col xl="6" lg="6" sm="12">
        <ValidationProvider name="Have Training Facility" vid="have_training_facility" rules="required" v-slot="{ errors }">
        <b-form-group
            label-for="have_training_facility"
            label-cols-sm="4"
        >
            <template v-slot:label>
            {{ $t('portal.have_training_facility') }} <span class="text-danger">*</span>
            </template>
            <b-form-radio-group
                style="margin-top:5px;"
                :id="have_training_facility"
                v-model="detail.have_training_facility"
                :options="yesNoList"
            ></b-form-radio-group>
            <div class="invalid-feedback d-block">
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
    <!-- have_day_care -->
    <b-col xl="6" lg="6" sm="12">
        <ValidationProvider name="Have Day Care" vid="have_day_care" rules="required" v-slot="{ errors }">
        <b-form-group
            label-for="have_day_care"
            label-cols-sm="4"
        >
            <template v-slot:label>
            {{ $t('portal.have_day_care') }} <span class="text-danger">*</span>
            </template>
            <b-form-radio-group
                style="margin-top:5px;"
                :id="have_day_care"
                v-model="detail.have_day_care"
                :options="yesNoList"
            ></b-form-radio-group>
            <div class="invalid-feedback d-block">
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
    <!-- have_mothers_club -->
    <b-col xl="6" lg="6" sm="12">
        <ValidationProvider name="Have Mother's Club" vid="have_mothers_club" rules="required" v-slot="{ errors }">
        <b-form-group
            label-for="have_mothers_club"
            label-cols-sm="4"
        >
            <template v-slot:label>
            {{ $t('portal.have_mothers_club') }} <span class="text-danger">*</span>
            </template>
            <b-form-radio-group
                style="margin-top:5px;"
                :id="have_mothers_club"
                v-model="detail.have_mothers_club"
                :options="yesNoList"
            ></b-form-radio-group>
            <div class="invalid-feedback d-block">
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
    <!-- have_pregnant_women_health_service -->
    <b-col xl="6" lg="6" sm="12">
        <ValidationProvider name="Have Pregnent Women Health Service" vid="have_pregnant_women_health_service" rules="required" v-slot="{ errors }">
        <b-form-group
            label-for="have_pregnant_women_health_service"
            label-cols-sm="4"
        >
            <template v-slot:label>
            {{ $t('portal.have_pregnant_women_health_service') }} <span class="text-danger">*</span>
            </template>
            <b-form-radio-group
                style="margin-top:5px;"
                :id="have_pregnant_women_health_service"
                v-model="detail.have_pregnant_women_health_service"
                :options="yesNoList"
            ></b-form-radio-group>
            <div class="invalid-feedback d-block">
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
   </b-row>
</div>
</template>
<script>
  import { teaGardenServiceBaseUrl } from '@/config/api_config'
  export default {
      name: 'Category5',
       props: ['detail'],
      data () {
          return {
              teaGardenServiceBaseUrl: teaGardenServiceBaseUrl
          }
      },
      computed: {
        yesNoList () {
            return [
                {
                    value: 1,
                    text: this.$i18n.locale === 'en' ? 'Yes' : 'হ্যাঁ'
                },
                {
                    value: 0,
                    text: this.$i18n.locale === 'en' ? 'No' : 'না'
                }
            ]
        }
      },
      methods: {
      }
  }
</script>
<style>
</style>
