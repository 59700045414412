<template>
<div>
   <b-row>
    <!-- avg_price_of_tea_exported -->
        <b-col xl="6" lg="6" sm="12">
            <ValidationProvider name="Average price of tea exported" vid="avg_price_of_tea_exported" :rules="`required|max_value:${getStandardValue(1)}`">
            <b-form-group
                slot-scope="{ valid, errors }"
                label-for="avg_price_of_tea_exported">
                <template v-slot:label>
                {{ $t('portal.avg_price_of_tea_exported') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                id="avg_price_of_tea_exported"
                v-model="detail.avg_price_of_tea_exported"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <small class="validation_msg"><i :title="$t('portal.standard_value') + ' ' + $n(getStandardValue(1))" class="ri-folder-info-line"></i><b> {{$t('portal.standard_value')}} {{$n(getStandardValue(1))}}</b></small>
                <div class="invalid-feedback">
                {{ errors[0] }}
                </div>
            </b-form-group>
            </ValidationProvider>
        </b-col>
        <!-- total_tea_export_volume -->
        <b-col xl="6" lg="6" sm="12">
            <ValidationProvider name="Total tea export volume" vid="total_tea_export_volume" :rules="`required|max_value:${getStandardValue(2)}`">
            <b-form-group
                slot-scope="{ valid, errors }"
                label-for="total_tea_export_volume">
                <template v-slot:label>
                {{ $t('portal.total_tea_export_volume') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                id="total_tea_export_volume"
                v-model="detail.total_tea_export_volume"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <small class="validation_msg"><i :title="$t('portal.standard_value') + ' ' + $n(getStandardValue(2))" class="ri-folder-info-line"></i><b> {{$t('portal.standard_value')}} {{$n(getStandardValue(2))}}</b></small>
                <div class="invalid-feedback">
                {{ errors[0] }}
                </div>
            </b-form-group>
            </ValidationProvider>
        </b-col>
        <!-- number_of_countries_exported -->
        <b-col xl="6" lg="6" sm="12">
            <ValidationProvider name="Number of countries exported" vid="number_of_countries_exported" :rules="`required|max_value:${getStandardValue(3)}`">
            <b-form-group
                slot-scope="{ valid, errors }"
                label-for="number_of_countries_exported">
                <template v-slot:label>
                {{ $t('portal.number_of_countries_exported') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                id="number_of_countries_exported"
                v-model="detail.number_of_countries_exported"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <small class="validation_msg"><i :title="$t('portal.standard_value') + ' ' + $n(getStandardValue(3))" class="ri-folder-info-line"></i><b> {{$t('portal.standard_value')}} {{$n(getStandardValue(3))}}</b></small>
                <div class="invalid-feedback">
                {{ errors[0] }}
                </div>
            </b-form-group>
            </ValidationProvider>
        </b-col>
        <!-- total_exported_qty_value_added_tea -->
        <b-col xl="6" lg="6" sm="12">
            <ValidationProvider name="Total exported Quantity of Value added tea" vid="total_exported_qty_value_added_tea" :rules="`required|max_value:${getStandardValue(4)}`">
            <b-form-group
                slot-scope="{ valid, errors }"
                label-for="total_exported_qty_value_added_tea">
                <template v-slot:label>
                {{ $t('portal.total_exported_qty_value_added_tea') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                id="total_exported_qty_value_added_tea"
                v-model="detail.total_exported_qty_value_added_tea"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <small class="validation_msg"><i :title="$t('portal.standard_value') + ' ' + $n(getStandardValue(4))" class="ri-folder-info-line"></i><b> {{$t('portal.standard_value')}} {{$n(getStandardValue(4))}}</b></small>
                <div class="invalid-feedback">
                {{ errors[0] }}
                </div>
            </b-form-group>
            </ValidationProvider>
        </b-col>
   </b-row>
</div>
</template>
<script>
  import { teaGardenServiceBaseUrl } from '@/config/api_config'
  export default {
      name: 'Category3',
       props: ['detail', 'categoryDetails'],
      data () {
          return {
              teaGardenServiceBaseUrl: teaGardenServiceBaseUrl
          }
      },
      methods: {
        getStandardValue (key) {
           return this.categoryDetails.find((value, index) => parseInt(index + 1) === key).standard_value
        }
      }
  }
</script>
<style>
</style>
