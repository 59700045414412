<template>
<div>
   <b-row>
    <!-- details data start from here -->
        <b-col xl="6" lg="6" sm="12">
            <ValidationProvider name="Average production per acre" vid="avg_prod_per_acor" :rules="`required|max_value:${getStandardValue(1)}`">
            <b-form-group
                slot-scope="{ valid, errors }"
                label-for="avg_prod_per_acor">
                <template v-slot:label>
                {{ $t('portal.avg_prod_per_acor') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                id="avg_prod_per_acor"
                v-model="detail.avg_prod_per_acor"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <small class="validation_msg"><i :title="$t('portal.standard_value') + ' ' + $n(getStandardValue(1))" class="ri-folder-info-line"></i><b> {{$t('portal.standard_value')}} {{$n(getStandardValue(1))}}</b></small>
                <div class="invalid-feedback">
                {{ errors[0] }}
                </div>
            </b-form-group>
            </ValidationProvider>
        </b-col>
        <!-- total_cultivation_area_under_25_acor -->
        <b-col xl="6" lg="6" sm="12">
            <ValidationProvider name="Total tea cultivation area (Condition Under 25 acre)" vid="total_cultivation_area_under_25_acor" :rules="`required|max_value:${getStandardValue(2)}`">
            <b-form-group
                slot-scope="{ valid, errors }"
                label-for="total_cultivation_area_under_25_acor">
                <template v-slot:label>
                {{ $t('portal.total_cultivation_area_under_25_acor') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                id="total_cultivation_area_under_25_acor"
                v-model="detail.total_cultivation_area_under_25_acor"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <small class="validation_msg"><i :title="$t('portal.standard_value') + ' ' + $n(getStandardValue(2))" class="ri-folder-info-line"></i><b> {{$t('portal.standard_value')}} {{$n(getStandardValue(2))}}</b></small>
                <div class="invalid-feedback">
                {{ errors[0] }}
                </div>
            </b-form-group>
            </ValidationProvider>
        </b-col>
   </b-row>
</div>
</template>
<script>
  import { teaGardenServiceBaseUrl } from '@/config/api_config'
  export default {
      name: 'Category4',
       props: ['detail', 'categoryDetails'],
      data () {
          return {
              teaGardenServiceBaseUrl: teaGardenServiceBaseUrl
          }
      },
      methods: {
        getStandardValue (key) {
           return this.categoryDetails.find((value, index) => parseInt(index + 1) === key).standard_value
        }
      }
  }
</script>
<style>
</style>
