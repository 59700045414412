<template>
<div>
   <b-row class="btb_license_file_label">
     <!-- packet_picture_attachment -->
     <b-col xs="6" sm="12" md="6" lg="6" xl="6">
        <ValidationProvider name="Packet Picture Upload" vid="packet_picture_attachment" rules="required">
            <b-form-group
            label-for="packet_picture_attachment"
            slot-scope="{ valid, errors }"
            >
            <template v-slot:label>
                {{ $t('portal.packet_picture_attachment') }}<span class="text-danger">*</span>
            </template>
            <b-form-file
                id="packet_picture_attachment"
                v-model="detail.packet_picture_attachment"
                @change="onFileChange($event, 'packet_picture_attachment')"
                accept=".pdf,.jpg,.png,.jpeg"
                :state="errors[0] ? false : (valid ? true : null)"
                :placeholder="$t('globalTrans.attachment_placeholder')"
                :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
            ></b-form-file>
            <a target="_blank" style = "margin-left: 5px;"
                v-if="detail.packet_picture_attachment && checkBase64(detail.packet_picture_attachment) === false"
                :href="teaGardenServiceBaseUrl + (isImage(detail.packet_picture_attachment) ? 'storage/' : '') + detail.packet_picture_attachment"
                class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i>
            </a>
            <div class="invalid-feedback">
                {{ errors[0] }}
            </div>
            </b-form-group>
        </ValidationProvider>
    </b-col>
     <!-- ingredients_use_in_packet -->
    <b-col xl="6" lg="6" sm="12">
        <ValidationProvider name="Ingredients used in the packet" vid="ingredients_use_in_packet" rules="required">
        <b-form-group
            slot-scope="{ valid, errors }"
            label-for="ingredients_use_in_packet">
            <template v-slot:label>
            {{ $t('portal.ingredients_use_in_packet') }} <span class="text-danger">*</span>
            </template>
            <textarea
                class="form-control"
                id="ingredients_use_in_packet"
                v-model="detail.ingredients_use_in_packet"
                :state="errors[0] ? false : (valid ? true : null)"
            ></textarea>
            <div class="invalid-feedback">
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
        <!-- packet_details_design_attachment -->
     <b-col xs="6" sm="12" md="6" lg="6" xl="6">
        <ValidationProvider name="Packet’s Details Design (Attachment)" vid="packet_details_design_attachment" rules="required">
            <b-form-group
            label-for="packet_details_design_attachment"
            slot-scope="{ valid, errors }"
            >
            <template v-slot:label>
                {{ $t('portal.packet_details_design_attachment') }}<span class="text-danger">*</span>
            </template>
            <b-form-file
                id="packet_details_design_attachment"
                v-model="detail.packet_details_design_attachment"
                @change="onFileChange($event, 'packet_details_design_attachment')"
                accept=".pdf,.jpg,.png,.jpeg"
                :state="errors[0] ? false : (valid ? true : null)"
                :placeholder="$t('globalTrans.attachment_placeholder')"
                :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"
            ></b-form-file>
            <a target="_blank" style = "margin-left: 5px;"
                v-if="detail.packet_details_design_attachment && checkBase64(detail.packet_details_design_attachment) === false"
                :href="teaGardenServiceBaseUrl + (isImage(detail.packet_details_design_attachment) ? 'storage/' : '') + detail.packet_details_design_attachment"
                class="btn-white btn-success p-1" size="sm"><i class="ri-eye-line"></i>
            </a>
            <div class="invalid-feedback">
                {{ errors[0] }}
            </div>
            </b-form-group>
        </ValidationProvider>
    </b-col>
    <!-- packet_uses_facilities -->
    <b-col xl="6" lg="6" sm="12">
        <ValidationProvider name="Ingredients used in the packet" vid="packet_uses_facilities" rules="required">
        <b-form-group
            slot-scope="{ valid, errors }"
            label-for="packet_uses_facilities">
            <template v-slot:label>
            {{ $t('portal.packet_uses_facilities') }} <span class="text-danger">*</span>
            </template>
            <textarea
                class="form-control"
                id="packet_uses_facilities"
                v-model="detail.packet_uses_facilities"
                :state="errors[0] ? false : (valid ? true : null)"
            ></textarea>
            <div class="invalid-feedback">
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
   </b-row>
</div>
</template>
<script>
  import { teaGardenServiceBaseUrl } from '@/config/api_config'
  export default {
      name: 'Category7',
       props: ['detail'],
      data () {
          return {
              teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
              attachement: ''
          }
      },
      computed: {
        yesNoList () {
            return [
                {
                    value: 1,
                    text: this.$i18n.locale === 'en' ? 'Yes' : 'হ্যাঁ'
                },
                {
                    value: 0,
                    text: this.$i18n.locale === 'en' ? 'No' : 'না'
                }
            ]
        }
      },
      methods: {
        onFileChange (event, property) {
            const input = event.target
            if (input.files && input.files[0]) {
                const reader = new FileReader()
                reader.onload = (e) => {
                    this.detail[property] = e.target.result
                }
                reader.readAsDataURL(input.files[0])
            } else {
                this.detail[property] = ''
            }
        },
        checkBase64 (string) {
            var result = ''
            result = string.match('data:')
            if (result) {
                return true
            } else {
                return false
            }
        },
        isImage (path) {
            return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
        }
      }
  }
</script>
<style>
.font-bn .btb_license_file_label .custom-file-label {
  font-size: 14px;
  color: #5a5959;
}
.validation_msg {
  font-size: 11px;
}
</style>
