<template>
<div>
   <b-row>
    <!-- workers_type -->
    <b-col xl="6" lg="6" sm="12">
        <ValidationProvider name="Worker's Type" vid="workers_type" rules="required" v-slot="{ errors }">
        <b-form-group
            label-for="workers_type"
            label-cols-sm="4"
        >
            <template v-slot:label>
            {{ $t('portal.workers_type') }} <span class="text-danger">*</span>
            </template>
            <b-form-radio-group
                style="margin-top:5px;"
                :id="workers_type"
                v-model="detail.workers_type"
                :options="workerType"
            ></b-form-radio-group>
            <div class="invalid-feedback">
            {{ errors[0] }}
            </div>
        </b-form-group>
        </ValidationProvider>
    </b-col>
   </b-row>
</div>
</template>
<script>
  import { teaGardenServiceBaseUrl } from '@/config/api_config'
  export default {
      name: 'Category8',
       props: ['detail', 'workerType'],
      data () {
          return {
              teaGardenServiceBaseUrl: teaGardenServiceBaseUrl
          }
      },
      methods: {
      }
  }
</script>
<style>
</style>
